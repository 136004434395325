<template>
  <div class="solution-info-home" v-loading="loading">
    <div class="info-main-header">
      <div class="info-main-label">
        <div
          class="info-main-text"
          v-if="solutionNotice?.typeCategory || solutionNotice?.solutionTime"
        >
          <i class="el-icon-info"></i>
          <span v-if="solutionNotice.typeCategory">
            {{ solutionNotice.typeCategory + '-' + solutionNotice.typeName }}
          </span>
          <span v-if="solutionNotice.solutionTime">
            {{
              solutionNotice.solutionTime
                ? '开始时间：' + solutionNotice.solutionTime?.substring(0, 10)
                : ''
            }}
            {{
              solutionNotice.solutionFinishTime
                ? '-结束时间：' + solutionNotice.solutionFinishTime?.substring(0, 10)
                : ''
            }}
          </span>
          <span v-if="info?.status == 'FINISH'" type="success">已结束</span>
          <span v-else-if="info?.status == 'CONTINUE'">进行中</span>
          <span v-else-if="info?.status == 'ABORT'" type="danger">中止</span>
        </div>
        <div
          class="info-main-text"
          v-if="solutionNotice?.nextFollowDate || solutionNotice?.nextOpinionTime"
        >
          <i class="el-icon-info"></i>
          <span v-if="solutionNotice.nextFollowDate">
            下次随访日期：{{ solutionNotice.nextFollowDate }}
          </span>
          <span v-if="solutionNotice.nextOpinionTime">
            下次复诊日期：{{ solutionNotice.nextOpinionTime }}
          </span>
        </div>
      </div>
    </div>
    <div class="info-main">
      <div class="info-main-left">
        <div class="card-pd">
          <div class="info-main-left-content">
            <el-card class="box-card">
              <div slot="header" class="clearfix card-between">
                <div>
                  <span>治疗方案</span>
                </div>
              </div>
              <div class="info-content info-content-main">
                <div class="info-label" v-if="info.complication || !isEdit">
                  <div class="info-title">合并症：</div>
                  <div v-if="isEdit">{{ info.complication }}</div>
                  <div v-else>
                    <el-input
                      type="textarea"
                      :rows="2"
                      v-model="solutionInfo.complication"
                    ></el-input>
                  </div>
                </div>

                <div class="info-label" v-if="info.typeCategory || !isEdit">
                  <div class="info-title">方案类型：</div>
                  <div v-if="isEdit">{{ info.typeCategory + '-' + info.typeName }}</div>
                  <div v-else>
                    <el-cascader
                      v-model="typeValue"
                      :options="options"
                      :props="{ expandTrigger: 'hover' }"
                      @change="handleChange"
                    ></el-cascader>
                  </div>
                </div>
                <div class="info-label" v-if="info.title || !isEdit">
                  <div class="info-title">方案详情：</div>
                  <div v-if="isEdit">{{ info.title }}</div>
                  <div v-else>
                    <el-input type="textarea" :rows="2" v-model="solutionInfo.title"></el-input>
                  </div>
                </div>

                <div v-if="info?.solutionTime && info.solutionFinishTime" class="info-label">
                  <div class="info-title">治疗周期：</div>
                  <div v-if="info?.solutionTime">
                    <span>{{ info?.solutionTime }}</span>
                    <span v-if="info.solutionFinishTime">{{ '-' + info.solutionFinishTime }}</span>
                  </div>
                </div>
                <div v-else class="info-label">
                  <div class="info-title">治疗开始：</div>
                  <div>
                    <span>{{ info?.solutionTime }}</span>
                  </div>
                </div>

                <div class="info-label">
                  <div class="info-title">方案状态：</div>
                  <el-tag v-if="info?.status == 'FINISH'" type="success">已结束</el-tag>
                  <el-tag v-else-if="info?.status == 'CONTINUE'">进行中</el-tag>
                  <el-tag v-else-if="info?.status == 'ABORT'" type="danger">中止</el-tag>
                </div>

                <div class="info-label">
                  <div class="info-title">首诊时间：</div>
                  <div v-if="isEdit">{{ info.firstDiagnosisTime }}</div>
                  <div v-else>
                    <date-panel
                      style="width: 100%"
                      ref="datePanel"
                      v-model="solutionInfo.firstDiagnosisTime"
                      type="datetime"
                      align="center"
                      placeholder="选择日期时间"
                      value-format="yyyy-MM-dd HH:mm"
                      format="yyyy-MM-dd HH:mm"
                    ></date-panel>
                  </div>
                </div>
                <div class="info-label">
                  <div class="info-title">首诊分期：</div>
                  <div v-if="isEdit">{{ info.firstDiagnosisLevel }}</div>
                  <div v-else>
                    <el-input
                      class="input_item"
                      style="width: 100%"
                      type="text"
                      placeholder="请输入"
                      v-model="solutionInfo.firstDiagnosisLevel"
                    ></el-input>
                  </div>
                </div>
              </div>
              <div class="info-footer" v-show="isEdit">
                <span>创建人：{{ info.createName }} 创建时间：{{ info.createAt }}</span>
              </div>
            </el-card>
          </div>
          <div class="info-main-left-content">
            <el-card class="box-card" :body-style="{ padding: '0 0 0 10px' }">
              <div slot="header" class="clearfix">
                <span>病情进展</span>
              </div>
              <div class="info-timeAxis">
                <div
                  v-for="(item, index) in trendData"
                  :key="index"
                  class="timeAxis-Box"
                  :id="'timeAxis' + index"
                >
                  <div class="timeAxis-info" v-if="item.opinions">
                    <div>评价时间：{{ item.opinions[item.opinionsIndex]?.opinionTime }}</div>
                    <div>评价：{{ item.opinions[item.opinionsIndex]?.ote }}</div>
                    <div>{{ item.opinions[item.opinionsIndex]?.recordName }}</div>
                  </div>
                  <div class="timeAxis-tag" :id="'timeAxisTagList' + index">
                    <div
                      class="timeAxis-tag-item"
                      v-for="ele in item.opinions"
                      :key="ele.id"
                      :id="'timeAxisTag' + ele.id"
                    >
                      <img :src="timeAxisTag" alt="" @click="handleTimeAxisInfo(index, ele.id)" />
                      <div class="timeAxis-tag-title" @click="handleTimeAxisInfo(index, ele.id)">
                        {{ ele.ote }}
                      </div>
                      <div class="timeAxis-tag-time">
                        {{ ele.opinionTime }}
                      </div>
                    </div>
                  </div>
                  <div class="timeAxis-content-left"></div>
                  <div class="timeAxis-content" :id="'timeAxisContent' + index">
                    {{ item.solutionTitle }}
                  </div>
                  <div class="timeAxis-content-right" :id="'timeAxisContentRight' + index"></div>

                  <div class="timeAxis-date-start">
                    {{ item.solutionStart?.substring(0, 10) }}
                  </div>
                  <div class="timeAxis-date-end">
                    {{ item.solutionEnd?.substring(0, 10) }}
                  </div>
                </div>
              </div>
            </el-card>
          </div>
        </div>
      </div>
      <div class="info-main-right">
        <div class="card-pd">
          <div class="info-main-right-content">
            <el-card class="box-card medical" :body-style="{ padding: 0 }">
              <div slot="header" class="clearfix">
                <span>最近医嘱</span>
              </div>
              <div class="medical-content">
                <MedicalTable ref="MedicalTable" :info="info" />
              </div>
            </el-card>
          </div>
          <div class="info-main-right-content">
            <el-card class="box-card" :body-style="{ padding: 0 }">
              <div slot="header" class="clearfix">
                <span>最近介入手术</span>
              </div>
              <div>
                <el-table
                  class="card-border-none"
                  :data="oprationTableData"
                  style="width: 100%"
                  height="22vh"
                >
                  <el-table-column
                    align="center"
                    prop="examineNames"
                    label="手术名称"
                    width="280"
                    show-overflow-tooltip
                  ></el-table-column>
                  <el-table-column align="center" label="手术时间">
                    <template slot-scope="scope">
                      <span v-if="scope.row.studyStartDate">
                        {{ scope.row.studyStartDate }}
                      </span>
                      <span v-if="scope.row.studyEndDate">
                        {{ scope.row.studyEndDate }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    prop="doctorName"
                    label="手术医生"
                    width="180"
                  ></el-table-column>
                  <el-table-column label="操作" align="center" width="100">
                    <template slot-scope="scope">
                      <el-button
                        @click="handleBtn(scope.row, 'operation')"
                        type="text"
                        size="small"
                      >
                        查看
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-card>
          </div>
          <div class="info-main-right-content">
            <el-card class="box-card" :body-style="{ padding: 0 }">
              <div slot="header" class="clearfix">
                <span>最近随访</span>
              </div>
              <div>
                <el-table
                  class="card-border-none"
                  :data="followTableData"
                  style="width: 100%"
                  height="22vh"
                >
                  <el-table-column align="center" label="随访阶段" width="120">
                    <template slot-scope="scope">
                      <span v-if="scope.row.followTitle">{{ scope.row.followTitle }}</span>
                      <span v-else>{{ scope.row.followType }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="电话回访时间/随访时间">
                    <template slot-scope="scope">
                      <span v-if="scope.row.followDate">
                        {{ scope.row.followDate }}
                      </span>
                      <span v-else-if="scope.row.planFollowDate">
                        {{ scope.row.planFollowDate?.substring(0, 10) }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" prop="memo" label="随访详情"></el-table-column>
                  <!-- <el-table-column label="操作" align="center" width="100">
                    <template slot-scope="scope">
                      <el-button
                        v-if="scope.row.followTitle && scope.row.operationId"
                        @click="handleBtn(scope.row, 'follow')"
                        type="text"
                        size="small"
                      >
                        查看
                      </el-button>
                    </template>
                  </el-table-column> -->
                </el-table>
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import deepClone from '@/plugins/clone'
import MedicalTable from './components/MedicalTable.vue'

export default {
  name: 'solutionInfo',
  components: {
    MedicalTable
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isEdit: true,
      loading: true,
      timeAxisTag: require('@/assets/img/down.png'),
      colorList: ['#FF0000', '#ef7a2f', '#2CF2C5', '#6FAD4A', '#FEBD01', '#3E70CA'],
      trendData: [],
      trendLabel: [],
      oprationTableData: [],
      followTableData: [],
      typeValue: [],
      solutionInfo: null,
      solutionNotice: null,
      options: []
    }
  },

  watch: {
    info: {
      handler(val) {
        if (val) {
          this.loading = true
          this.isEdit = true
          let arr = []
          this.solutionInfo = deepClone(val)
          arr.push(this.solutionInfo.typeCategory)
          arr.push(this.solutionInfo.typeName)
          this.typeValue = arr
          this.getTrendChart()
          this.getOprationTable()
          this.getFollowTable()
          this.getSolutionNotice()
          this.getSolutionType()
          setTimeout(() => {
            this.loading = false
          }, 500)
        }
      },
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    handleBtn(val, type) {
      if (type === 'operation') {
        const { href } = this.$router.resolve({
          path: `/Detail/BaseInfo?operationId=${val.uuid}`
        })
        window.open(href, '_blank')
      } else if (type === 'follow') {
        const { href } = this.$router.resolve({
          path: `/follow/follow?stage=${val.followTitle}&moduleCode=${val.moduleCode}&operationId=${val.operationId}`
        })
        window.open(href, '_blank')
      }
    },
    // editClick() {
    //   this.isEdit = false
    // },
    saveClick() {
      let data = {
        id: this.info.id,
        typeCategory: this.solutionInfo.typeCategory,
        typeName: this.solutionInfo.typeName,
        title: this.solutionInfo.title,
        firstDiagnosisSymptomSign: this.solutionInfo.firstDiagnosisSymptomSign,
        complication: this.solutionInfo.complication,
        chiefComplaint: this.solutionInfo.chiefComplaint
      }

      this.$api.post(`/v1/webconsole/solution/content/save/${this.info.id}`, data).then(() => {
        this.$bus.$emit('getPlanList')
        // this.isEdit = true
        return this.$message.success('保存成功')
      })
    },
    // closeClick() {
    //   this.solutionInfo = deepClone(this.info)
    //   this.isEdit = true
    // },
    handleChange() {
      this.solutionInfo.typeCategory = this.typeValue[0]
      this.solutionInfo.typeName = this.typeValue[1]
    },

    getSolutionType() {
      this.$api
        .get(`/v1/webconsole/solution/disease/solutionType/${this.info.diseaseModule}`)
        .then((res) => {
          if (res.data && res.data.data) {
            const cascaderData = {}
            // 遍历原始数组，按照 typeCategory 进行分组
            res.data.data.forEach((item) => {
              if (!cascaderData[item.typeCategory]) {
                cascaderData[item.typeCategory] = []
              }
              cascaderData[item.typeCategory].push({
                label: item.typeName,
                value: item.typeName
              })
            })
            // 将分组后的数据转换为级联选择框需要的格式
            const cascaderOptions = Object.keys(cascaderData).map((category) => ({
              label: category,
              value: category,
              children: cascaderData[category]
            }))
            this.options = cascaderOptions
          }
        })
    },
    getSolutionNotice() {
      this.$api.get(`/v1/webconsole/solution/notice/${this.info.id}`).then((res) => {
        if (res.data && res.data.data) {
          this.solutionNotice = res.data.data
        }
      })
    },
    getOprationTable() {
      this.$api.get(`/v1/webconsole/study/query/solution/${this.info.id}`).then((res) => {
        if (res.data && res.data.data) {
          this.oprationTableData = res.data.data
        }
      })
    },
    // 获取最近随访
    getFollowTable() {
      this.$api.get(`/v1/webconsole/follow/list/follows/solution/${this.info.id}`).then((res) => {
        if (res.data && res.data.data) {
          this.followTableData = res.data.data.records
        }
      })
    },
    handleTimeAxisInfo(index, id) {
      this.trendData[index].opinions.forEach((ele, idx) => {
        if (ele.id === id) {
          this.trendData[index].opinionsIndex = idx
        }
      })
      this.$forceUpdate()
    },
    getTrendChart() {
      this.$api.get(`/v1/webconsole/solution/disease/progression/${this.info.id}`).then((res) => {
        if (res.data && res.data.data) {
          this.trendData = res.data.data
          console.log(this.trendData)
          setTimeout(() => {
            this.initData()
          }, 200)
        }
      })
    },
    calculateDaysDifference(solutionEnd, solutionStart) {
      const solutionEndDate = new Date(solutionEnd)
      const solutionStartDate = new Date(solutionStart)
      const differenceInMillis = solutionEndDate - solutionStartDate
      const differenceInDays = differenceInMillis / (1000 * 60 * 60 * 24) // 将毫秒转换为天数
      return Math.round(differenceInDays) // 如果需要四舍五入，可以使用 Math.round
    },
    formatDate(date) {
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    initData() {
      this.trendData.forEach((element, index) => {
        element.opinionsIndex = 0
        if (!element.solutionEnd) {
          element.solutionEnd = this.formatDate(new Date())
          element.durationDays = this.calculateDaysDifference(
            element.solutionEnd,
            element.solutionStart
          )
        }
        let id = 'timeAxis' + index
        let colorId = 'timeAxisContent' + index
        let colorRightId = 'timeAxisContentRight' + index
        let tagWidth = 0
        let timing = 0
        document.getElementById(id).style.width = 200 + element.durationDays / 2 + 'px'
        document.getElementById(id).style.flexShrink = 0
        tagWidth = 180 + element.durationDays / 2
        timing = new Date(element.solutionEnd) - new Date(element.solutionStart)
        if (index < 5) {
          document.getElementById(colorId).style.background = this.colorList[index]
          document.getElementById(
            colorRightId
          ).style.borderColor = `transparent transparent transparent ${this.colorList[index]}`
        } else {
          document.getElementById(colorId).style.background = this.colorList[index - 5]
          document.getElementById(
            colorRightId
          ).style.borderColor = `transparent transparent transparent ${this.colorList[index - 5]}`
        }
        if (element.opinions?.length > 0) {
          element.opinions.forEach((ele) => {
            let tagLeftId = 'timeAxisTag' + ele.id
            let timingLeft = (new Date(ele.opinionTime) - new Date(element.solutionStart)) / timing
            document.getElementById(tagLeftId).style.left = parseInt(tagWidth * timingLeft) + 'px'
          })
        }
      })
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped lang="less">
.solution-info-home {
  height: 100%;
  .el-card {
    margin-bottom: 10px;
  }
  .box-card {
    /deep/.el-card__header {
      padding: 10px 20px 5px;
    }
    .clearfix {
      font-weight: bold;
      font-size: 18px;
    }
    .card-border-none {
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
    .card-between {
      display: flex;
      justify-content: space-between;
    }
    .info-content-main {
      overflow: auto;
    }
    .info-content {
      .info-label {
        display: flex;
        align-items: center;
        margin: 10px 0;
        font-size: 18px;
        .el-input {
          width: 500px;
        }
        .el-cascader {
          width: 320px;
        }
        .el-textarea {
          width: 450px;
        }
        .info-title {
          width: 130px;
          font-weight: bold;
          white-space: nowrap;
          flex-shrink: 0;
        }
      }
    }
    .info-footer {
      position: absolute;
      bottom: 10px;
      right: 20px;
      text-align: right;
      width: calc(100% - 30px);
    }
    .info-timeAxis {
      display: flex;
      padding-top: 160px;
      padding-bottom: 140px;
      overflow-x: auto;
      .timeAxis-Box {
        min-width: 200px;
        position: relative;
        margin-right: 5px;
      }
      .timeAxis-content {
        height: 30px;
        line-height: 30px;
        background: #ef7a2f;
        text-align: center;
      }
      .timeAxis-info {
        width: 200px;
        position: absolute;
        top: -120px;
        left: 10px;
      }
      .timeAxis-tag {
        position: absolute;
        top: 0;
        img {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
        .timeAxis-tag-item {
          position: absolute;
          top: -24px;
          display: flex;
          justify-content: center;
          .timeAxis-tag-title {
            position: absolute;
            top: -24px;
            font-weight: bold;
            cursor: pointer;
          }
          .timeAxis-tag-time {
            writing-mode: vertical-rl;
            letter-spacing: 1px;
            white-space: nowrap;
            transform: rotate(180deg);

            position: absolute;

            top: 54px;
            font-weight: bold;
          }
        }
      }
      .timeAxis-content-left {
        position: absolute;
        top: 0;
        float: right;
        width: 0;
        height: 0;
        border-width: 15.5px;
        border-style: solid;
        border-color: transparent transparent transparent #fff;
        z-index: 10;
      }
      .timeAxis-content-right {
        position: absolute;
        top: 0;
        right: -30px;
        float: right;
        width: 0;
        height: 0;
        border-width: 15.5px;
        border-style: solid;
        border-color: transparent transparent transparent #ef7a2f;
        z-index: 19;
      }

      .timeAxis-date-start {
        writing-mode: vertical-rl;
        letter-spacing: 1px;
        white-space: nowrap;
        transform: rotate(180deg);

        position: absolute;
        left: 0;
        font-weight: bold;
      }
      .timeAxis-date-end {
        writing-mode: vertical-rl;
        letter-spacing: 1px;
        white-space: nowrap;
        transform: rotate(180deg);

        position: absolute;
        right: 0;
        font-weight: bold;
      }
    }
  }
  .info-main-header {
    min-height: 51px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    .info-main-label {
      color: red;
      padding-left: 20px;
      font-size: 16px;
    }
    .info-main-text {
      height: 30px;
      line-height: 30px;
      color: red;
      padding-left: 20px;
      font-size: 16px;
    }
  }
  .info-main {
    display: flex;
    height: calc(100% - 62px);
  }
  .info-main-left {
    width: 50%;
    height: 100%;
    .card-pd {
      height: calc(100% - 15px);
      padding: 15px 20px 0;
      .info-main-left-content {
        height: calc(50% - 12px);
        margin-bottom: 10px;
      }
    }
    .el-card {
      height: 100%;
      display: flex;
      flex-direction: column;
      /deep/.el-card__body {
        flex: 1;
        overflow: auto;
        position: relative;
      }
    }
  }
  .info-main-right {
    width: 50%;
    height: 100%;
    .card-pd {
      height: calc(100% - 15px);
      padding: 15px 10px 0 0;
      .info-main-right-content {
        height: calc(33% - 9px);
        margin-bottom: 10px;
      }
    }
    .el-card {
      height: 100%;
    }
  }

  .medical {
    .el-card__body {
      .medical-content {
        width: 100%;
        height: 200px;
      }
    }
  }
}
</style>
