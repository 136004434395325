<template>
  <div v-loading="loading" class="container">
    <div class="content-box">
      <div class="component">
        <el-table
          class="card-border-none"
          :data="tableData"
          :stripe="false"
          :highlight-current-row="false"
          :header-cell-style="{ padding: 0 }"
          border
          height="20vh"
          style="width: 100%"
        >
          <el-table-column prop="doctor" align="center" label="医生"></el-table-column>
          <el-table-column prop="prescriptionNum" align="center" label="处方号"></el-table-column>
          <el-table-column
            prop="openTime"
            align="center"
            label="时间"
            width="180"
          ></el-table-column>
          <el-table-column prop="openTime" align="center" label="操作" width="120">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="getDetail(scope.row.uuid)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MedicalTable',
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isReadonly: true,
      loading: false,
      tableData: [],
      list: [],
      currentPage: 1,
      pageSize: 50
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val && val?.id) {
          this.initData()
        }
      },
      immediate: true
    }
  },
  methods: {
    initData() {
      const params = new FormData()
      params.append('current', this.currentPage)
      params.append('size', this.pageSize)
      this.$api
        .post(`/v1/webconsole/doctoradvice/list/advice/patient/${this.info.patientIndex}`, params)
        .then(({ data }) => {
          if (data.status === 200) {
            this.tableData = data.data.records
          }
        })
    },
    getDetail(uuid) {
      this.$router.push({
        path: `Medication`,
        query: { uuid }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  .component {
    .card-border-none {
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
    .danger-btn {
      color: red;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
    }
    .content-title {
      width: 99%;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      padding: 15px 0;
      border-bottom: 1px solid rgb(223, 220, 220);
      float: left;
      div {
        border-left: 4px solid rgb(1, 72, 226);
        padding-left: 5px;
      }
    }

    .deep {
      width: 100%;
      float: left;
    }
    .content-basic {
      width: 99%;
      min-height: 30px;
      float: left;
      padding: 10px 0;
      font-size: 16px;
      display: flex;
      align-items: center;
    }
    .basic-div {
      min-width: 30%;
      min-height: 30px;
      float: left;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px;
      font-size: 16px;
      /deep/.editStyle {
        .el-input__inner {
          background-color: #ffffcc;
        }
      }
      /deep/.textareaEditStyle {
        .el-textarea__inner {
          background-color: #ffffcc;
        }
      }
      /deep/ .radioEditStyle {
        .is-checked {
          background-color: #ffffcc;
        }
      }
    }
    .basic-child {
      float: none;
    }
    /deep/.el-radio__input {
      display: none !important;
    }
    .select-title {
      min-width: 150px;
      white-space: nowrap;
    }
    /deep/.radio {
      word-break: normal;
      .el-radio__input {
        display: none !important;
      }
      .el-checkbox-group,
      .el-radio-group {
        margin: 0;
      }
      .el-input__inner {
        appearance: none;
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 30px;
        line-height: 30px;
        outline: none;
        padding: 0 15px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
      }
      .is-bordered {
        padding: 12px 20px 0 10px;
        border-radius: 4px;
        box-sizing: border-box;
        height: 30px;
        line-height: 5px;
      }
      // 日期选择框
      .el-date-editor--date {
        .el-input__inner {
          padding: 0 30px;
        }
      }
      .el-checkbox {
        padding-top: 5px;
      }
      // 只读
      .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
        border-color: #53565c;
      }
      .el-input.is-disabled .el-input__inner {
        background-color: #f5f7fa;
        border-color: #e4e7ed;
        color: #000;
        cursor: not-allowed;
      }
      .el-input .el-input__inner {
        color: #000;
      }
      .el-textarea .el-textarea__inner {
        color: #000;
      }
      .is-disabled .el-textarea__inner::placeholder {
        color: #000;
      }
      /* 谷歌 */
      .is-disabled .el-textarea__inner::-webkit-input-placeholder {
        color: #000;
      }
      /* 火狐 */
      .is-disabled .el-textarea__inner:-moz-placeholder {
        color: #000;
      }
      /*ie*/
      .is-disabled .el-textarea__inner:-ms-input-placeholder {
        color: #000;
      }

      .is-disabled .el-input__inner::placeholder {
        color: #000;
      }
      /* 谷歌 */
      .is-disabled .el-input__inner::-webkit-input-placeholder {
        color: #000;
      }
      /* 火狐 */
      .is-disabled .el-input__inner:-moz-placeholder {
        color: #000;
      }
      /*ie*/
      .is-disabled .el-input__inner:-ms-input-placeholder {
        color: #000;
      }
      .el-checkbox__input.is-disabled + span.el-checkbox__label {
        color: #53565c;
      }
      .el-radio.is-bordered.is-disabled.is-checked {
        border: 2px solid #53565c;
        .el-radio__label {
          color: #53565c;
        }
      }
      .el-radio__input.is-disabled + span.el-radio__label {
        color: #53565c;
      }
      .el-textarea.is-disabled {
        .el-textarea__inner {
          color: #000;
        }
      }
    }
    .div {
      width: 100%;
    }
    /deep/.el-checkbox-group,
    .el-radio-group {
      margin: 0;
    }
    .radio-textarea {
      width: 800px;
    }
    div {
      margin-right: 10px;
    }
    /deep/.el-input__inner {
      appearance: none;
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 30px;
      line-height: 30px;
      outline: none;
      padding: 0 15px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
    }
    /deep/.is-bordered {
      padding: 12px 20px 0 10px;
      border-radius: 4px;
      box-sizing: border-box;
      height: 30px;
      line-height: 5px;
    }
  }

  .input_has_unit {
    margin-left: 0px !important;
  }
}
/deep/.el-table .warning-row {
  background-color: rgba(76, 75, 75, 0.04);
}

/deep/.el-table .success-row {
  background-color: #fff;
}
</style>
